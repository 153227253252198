import Cookies from "js-cookie";

export const setCookie = ( name, value ) => {
    Cookies.set(name, value, { secure: true });
}

export const getCookie = ( name ) => {
    return Cookies.get(name);
}

// ToDo: remove this 14.02.25 
export const getCookie_V2 = async ( name ) => {
    return Cookies.get(name);
}

export const removeCookie = ( name ) => {
    Cookies.remove(name);
}

