import axios from 'axios';
// import Cookies from "js-cookie";
import { getCookie, setCookie, removeCookie } from '../cookiesjs/cookiesjs';
import { ServerURI, ServerPORT } from '../../utils/config/uri';
import { AUTH } from '../../utils/config/routes';
import { addMinutes } from 'date-fns';

const cookieAccessToken = "nn-client-access";
const cookieRefreshToken = "nn-client-refresh";
// const cookieExpiry = "nn-client-expiry";
// const sessionTime = 359;
// const routeBlackList = ["cashflow", ""];

class TokenManager {
    constructor() {
        this.isRefreshing = false;
        this.refreshTokenPromise = null;
    }

    // Enhanced logging method
    log(message, data = {}) {
        const timestamp = new Date().toISOString();
        console.log(`[TokenManager][${timestamp}] ${message}`, JSON.stringify(data, null, 2));
    }

    isTokenExpired(token) {
        this.log('Checking token expiration', { token: token ? 'Present' : 'Missing' });

        if (!token) {
            this.log('Token is considered expired - no token present');
            return true;
        }

        try {
            const decoded = this.decodeToken(token);
            const currentTime = Math.floor(Date.now() / 1000);
            const isExpired = decoded.exp <= currentTime + (5 * 60);

            this.log('Token expiration check', {
                currentTime,
                tokenExpiration: decoded.exp,
                timeRemaining: decoded.exp - currentTime,
                isExpired
            });

            return isExpired;
        } catch (error) {
            this.log('Token validation error', { error: error.message });
            return true;
        }
    }

    decodeToken(token) {
        try {
            // Basic JWT decoding (replace with proper library in production)
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } catch (error) {
            this.log('Token decode error', { error: error.message });
            throw error;
        }
    }

    async refreshAccessToken() {
        this.log('Starting token refresh process');
        // const refreshToken = Cookies.get("nn-client-refresh");
        const refreshToken = getCookie(cookieRefreshToken);

        if (!refreshToken) {
            this.log('No refresh token available', {
                cookies: document.cookie
            });
            throw new Error('No refresh token available');
        }

        try {
            this.log('Sending refresh token request', {
                url: `${ServerURI}:${ServerPORT}/${AUTH}/refreshtoken`
            });

            const response = await axios.post(
                `${ServerURI}:${ServerPORT}/${AUTH}/refreshtoken`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    }
                }
            );

            const { accessToken, refreshToken: newRefreshToken } = response.data;

            this.log('Token refresh successful', {
                newAccessTokenPresent: !!accessToken,
                newRefreshTokenPresent: !!newRefreshToken
            });

            // Update tokens in cookies
            // Cookies.set("nn-client-access", accessToken, { secure: true });
            // Cookies.set("nn-client-refresh", newRefreshToken, { secure: true });
            setCookie(cookieAccessToken, accessToken);
            setCookie(cookieRefreshToken, newRefreshToken);

            return accessToken;
        } catch (error) {
            this.log('Token refresh failed', {
                error: error.message,
                response: error.response ? error.response.data : 'No response'
            });

            // Cookies.remove("nn-client-access");
            // Cookies.remove("nn-client-refresh");
            removeCookie(cookieAccessToken);
            removeCookie(cookieRefreshToken);
            throw error;
        }
    }

    async getValidToken() {
        // const currentAccessToken = Cookies.get("nn-client-access");
        const currentAccessToken = getCookie(cookieAccessToken);

        this.log('Getting valid token', {
            isRefreshing: this.isRefreshing,
            currentTokenPresent: !!currentAccessToken
        });

        // If already refreshing, wait for the existing refresh
        if (this.isRefreshing) {
            this.log('Token refresh already in progress, waiting');
            return this.refreshTokenPromise;
        }

        // Check if token needs refresh
        if (this.isTokenExpired(currentAccessToken)) {
            this.log('Token is expired, initiating refresh');

            try {
                this.isRefreshing = true;

                // Create a single promise for token refresh
                this.refreshTokenPromise = this.refreshAccessToken();

                const newAccessToken = await this.refreshTokenPromise;

                this.log('Token refresh completed', {
                    newTokenPresent: !!newAccessToken
                });

                return newAccessToken;
            } catch (error) {
                this.log('Token refresh process failed', {
                    error: error.message
                });
                throw error;
            } finally {
                this.isRefreshing = false;
                this.refreshTokenPromise = null;
            }
        }

        return currentAccessToken;
    }
}

// Create a singleton instance
const tokenManager = new TokenManager();

// Create Axios Instance with Interceptors
const apiClient = axios.create({
    baseURL: `${ServerURI}:${ServerPORT}`
});

// Request Interceptor with Enhanced Logging
apiClient.interceptors.request.use(
    async (config) => {
        const requestId = Math.random().toString(36).substring(2, 9);

        console.log(`[Request Interceptor][${requestId}] Intercepting request`, {
            url: config.url,
            method: config.method
        });

        try {
            // ToDo: check whether less than half the time is remaining. E.g. every 1 hour or so..  
            // If yes: update the token
            // if no: just continue with the existing token


            // Get a valid token before each request
            const startTime = Date.now();
            const accessToken = await tokenManager.getValidToken();
            const duration = Date.now() - startTime;

            console.log(`[Request Interceptor][${requestId}] Token retrieval`, {
                duration: `${duration}ms`,
                tokenPresent: !!accessToken
            });

            // Set the Authorization header
            config.headers.Authorization = `Bearer ${accessToken}`;

            return config;
        } catch (error) {
            console.error(`[Request Interceptor][${requestId}] Token retrieval failed`, {
                error: error.message
            });
            return Promise.reject(error);
        }
    },
    (error) => Promise.reject(error)
);

// Response Interceptor with Enhanced Logging
apiClient.interceptors.response.use(
    (response) => {
        console.log('Response Interceptor - Successful response', {
            url: response.config.url,
            status: response.status
        });

        // const url = response.config.url
        // const route = route.substring(0, url.indexOf("?"));
        // const isBlackListed = (routeBlackList.indexOf(route) > -1);
        // if (isBlackListed === -1) {
        // // reset expiry timer when successful user activity recorded - for reference look in the component SessionTimer.js
        //     const eventDate = addMinutes(new Date(), sessionTime);
        //     const currentTime = new Date().getTime();
        //     const eventTime = eventDate.getTime();
        //     const remainingTime = Math.max(eventTime - currentTime, 0);
        //     setCookie(cookieExpiry, remainingTime);
        // }
        // console.log("response: ", response.status)

        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const requestId = originalRequest?.requestId || Math.random().toString(36).slice(2, 9);

        console.error(`[Response Interceptor][${requestId}] Error handling`, {
            url: originalRequest?.url,
            status: error.response?.status,
            error: error.message
        });

        // If unauthorized and not already a retry
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Force a token refresh
                await tokenManager.getValidToken();

                // Retry the original request
                return apiClient(originalRequest);
            } catch (refreshError) {
                console.error(`[Response Interceptor][${requestId}] Refresh failed`, {
                    error: refreshError.message
                });
                throw refreshError;
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;