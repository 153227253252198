import React, { useEffect, useState, useContext } from 'react';

// misc 
import { addMinutes } from 'date-fns';
import { toast } from 'react-toastify';

// mui
import Button from '@mui/material/Button';
import Refresh from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';

// internal
import { NovaContext } from '../context';
import { getCookie, setCookie, removeCookie } from '../../lib/cookiesjs/cookiesjs';

const sessionTime = 359;
const cookieName = "nn-client-expiry";

const SessionExpiring = ({ refreshSession, timeRemaining }) => (
    <div>
        <Typography>
            {`Oye! Tu sesión expirará en  ${Math.floor(timeRemaining / 60000)}`}:{((timeRemaining % 60000) / 1000).toFixed(0).padStart(2, '0')}
            {
                Math.floor(timeRemaining / 60000) <= 0 ?
                    " segundos"
                    :
                    " minutos"
            }
        </Typography>
        <Button
            onClick={refreshSession}
            color='success'
            startIcon={<Refresh />}
            variant='contained'
            fullWidth
        >
            Clic para actualizar
        </Button>
    </div>
);

const SessionTimer = () => {
    const [eventDate, setEventDate] = useState("");
    const [countdownStarted, setCountdownStarted] = useState(false);
    const [toastId, setToastId] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const { authenticatedUser, actions } = useContext(NovaContext)

    const refreshSession = () => {
        const newDate = addMinutes(new Date(), sessionTime);
        setEventDate(newDate);
        setToastId(null);
        toast.dismiss(toastId);
        setExpiryCookie(newDate);
    }

    const getExpiryCookie = () => {
        return getCookie(cookieName);
    }

    const setExpiryCookie = (dateToBeSet) => {
        setCookie(cookieName, dateToBeSet);
    }

    const clearExpiryCookie = () => {
        removeCookie(cookieName);
    }

    useEffect(() => {
        if (authenticatedUser) {
            if (getExpiryCookie()) {
                const existingDateRaw = Date.parse(getExpiryCookie());
                const existingDate = new Date(existingDateRaw);
                setEventDate(existingDate);
                setCountdownStarted(true);
                const currentTime = new Date().getTime();
                const eventTime = existingDate.getTime();
                const remainingTime = Math.max(eventTime - currentTime, 0);
                setTimeRemaining(remainingTime);
            } else {
                const eventDate = addMinutes(new Date(), sessionTime);
                const currentTime = new Date().getTime();
                const eventTime = eventDate.getTime();
                const remainingTime = Math.max(eventTime - currentTime, 0);
                setEventDate(eventDate);
                setCountdownStarted(true);
                setTimeRemaining(remainingTime);
                setExpiryCookie(eventDate);
            }
        }
    }, [authenticatedUser]);

    useEffect(() => {
        if (countdownStarted && eventDate) {
            const countdownInterval = setInterval(() => {
                const currentTime = new Date().getTime();
                const eventTime = new Date(eventDate).getTime();
                const remainingTime = Math.max(eventTime - currentTime, 0);
                setTimeRemaining(remainingTime);

                if (remainingTime <= 0) {
                    clearInterval(countdownInterval);
                    clearExpiryCookie();
                    actions.logout();
                }
            }, 1000);

            return () => clearInterval(countdownInterval);
        }
    }, [countdownStarted, eventDate]);

    useEffect(() => {
        if (timeRemaining < 60000 && timeRemaining > 0) {
            if (!toastId) {
                const id = toast.warning(<SessionExpiring timeRemaining={timeRemaining} refreshSession={refreshSession} />, {
                    position: "top-right",
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false
                });
                setToastId(id);
            } else {
                toast.update(toastId, {
                    render: <SessionExpiring timeRemaining={timeRemaining} refreshSession={refreshSession} />
                })
            }

            if (timeRemaining <= 1000 && toastId) {
                toast.dismiss(toastId);
                setToastId(null);
            }
        }
    }, [timeRemaining]);

}

export default SessionTimer;